/*
 *
 */
import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Section from '../components/section';
import Hero from '../components/hero';
import Button from '../components/button';
import H2 from '../components/h2';
import Coord from '../components/coord';
import Hours from '../components/hours';
import Quote from '../components/quote';
import Bio from '../components/bio';

/*
 *
 */
const IndexPage = () => {
	return (
		<React.Fragment>
			<title>Isabelle Kopp — Psychothérapeute à Rebecq</title>

			<Section>
				<Hero />
			</Section>

			<Section>
				<H2>À propos</H2>
				<Bio>
					<p>Formée à la thérapie psychanalytique individuelle en face à face, au soutien à la parentalité et aux difficultés d'attachement, aux processus du deuil, à la maternologie, à la clinique du lien tant chez l'adulte que dans les relations précoces parent/enfant et ce dès la naissance.</p>
				</Bio>
			</Section>

			<Section>
				<H2>Coordonnées</H2>
				<Coord />
			</Section>

			<Section>
				<H2>Horaire de consultation</H2>
				<Hours
					mon="08:00 - 19:00"
					tue="08:00 - 21:00"
					wed="08:00 - 19:00"
					thu="08:00 - 21:00"
					fri="08:00 - 19:00"
					sat="Fermé"
					sun="Fermé"
				/>
			</Section>

			<Section>
				<Quote>
					<p>Toute personne peut un jour ou l’autre sentir qu’elle a besoin d’être aidée, de parler, de déposer sa souffrance ou tout simplement d’être entendue.</p>
				</Quote>
				<Button to="/rendez-vous/" label="Prendre un rendez-vous" />
			</Section>
		</React.Fragment>
	);
};

export default IndexPage;
