/*
 *
 */
import * as React from "react";
import styled from 'styled-components';
import Button from '../button';

/*
 *
 */
const Hero = styled.div`
	margin:0 auto;
	width:100%;
	text-align:center;

	@media (min-width:1024px) {
		width:90%;
	}

	@media (min-width:1200px) {
		width:75%;
	}
`;

const Title = styled.h1`
	margin:0 0 5rem;

	font-size:2.4rem;
	font-weight:500;
	line-height:1.2992;
	text-align:center;
	letter-spacing:.015em;

	@media (min-width:768px) {
		font-size:3.4rem;
	}

	@media (min-width:1024px) {
		font-size:4.4rem;
	}
`;

const Subtitle = styled.p`
	margin:0 0 1.33em;
	opacity:.66;

	font-style:italic;
	font-size:1.575rem;
	text-align:center;

	@media (min-width:1024px) {
		font-size:1.8rem;
	}
`;

/*
 *
 */
export default () => {
	return (
		<Hero>
			<Title>Psychothérapeute pour adultes, enfants, adolescents, soutien à la parentalité et maternologue</Title>

			<Subtitle>Uniquement sur rendez-vous</Subtitle>

			<Button to="/rendez-vous/" label="Prendre un rendez-vous" />
		</Hero>
	);
}
