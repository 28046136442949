/*
 *
 */
import * as React from "react";
import styled from 'styled-components';
import { Link } from 'gatsby';

/*
 *
 */
const Bio = styled.blockquote`
	margin:0 auto 1.33em;

	max-width:920px;

	font-size:1.8rem;
	font-weight:500;
	line-height:1.3;
	text-align:center;

	& p {
		margin:0 0 1em;
	}

	&:first-child {
		margin-top:0;
	}

	&:last-child {
		margin-bottom:0;
	}

	@media (min-width:768px) {
		font-size:2.65rem;
	}

	@media (min-width:1024px) {
		font-size:3.5rem;
	}

`;

/*
 *
 */
export default ({ children }) => {
	return (
		<Bio>
			{children}
		</Bio>
	);
}
