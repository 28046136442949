/*
 *
 */
import * as React from "react";
import styled from 'styled-components';

/*
 *
 */
const Section = styled.section`
	/*padding-top:3.3vmax;
	padding-bottom:3.3vmax;*/

	padding-top:6vmax;
	padding-bottom:6vmax;
`;

/*
 *
 */
export default ({ children }) => {
	return (
		<Section>
			{children}
		</Section>
	);
}
