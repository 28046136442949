/*
 *
 */
import * as React from "react";
import styled from 'styled-components';
import { Link } from 'gatsby';
import mapsrc from './440x290@2x.png';

/*
 *
 */
const Coord = styled.div`
	margin:0 auto;
	display:flex;
	flex-direction:column;
	width:100%;
	max-width:920px;

	justify-content:center;
	align-items:center;

	@media (min-width:1024px) {
		flex-direction:row;
	}
`;

// https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/pin-s+ac4629(4.13199,50.6675)/4.13199,50.6675,13.75,0,0/400x295@2x?access_token=pk.eyJ1IjoicXVlbnRpbi1kIiwiYSI6ImNrbnlxdXRuZTBsd3Eyd3J4aWtkdWswczEifQ.tmGlG8IovhmHSLU_SejFAg
// https://docs.mapbox.com/playground/static/

const Map = styled.div`
	position:relative;
	width:100%;
	margin:0 auto;
	box-sizing:border-box;
	margin-bottom:2rem;
	max-width:320px;

	&:before {
		display:block;
		content:"";
		padding-top:65.9%;
		background-color:#E5E4E0;
		background:url(${mapsrc});
		background-size:cover;
	}

	@media (min-width:1024px) {
		width:50%;
		padding-right:2rem;
		max-width:none;
	}
`;

const Addr = styled.div`
	margin:0;

	& > p {
		margin:.66em 0;

		&:first-child {
			margin-top:0;
		}

		&:last-child {
			margin-bottom:0;
		}
	}

	display:flex;
	flex-direction:column;
	justify-content:space-around;
	width:100%;

	box-sizing:border-box;

	line-height:1.3em;
	font-size:1.4rem;
	letter-spacing:.032em;
	text-align:center;

	& a {
		text-decoration:none;
		border-bottom:1px solid;
	}


	@media (min-width:1024px) {
		width:50%;
		padding-left:2rem;
		text-align:left;
		font-size:1.6rem;

		& > p {
			margin:1.5em 0;
		}
	}
`;

/*
 *
 */
export default () => {
	return (
		<Coord>
			<Map />

			<Addr>
				<p>
					Rue de la Chapelle 47<br />
					1430 Rebecq<br />
					Belgique
				</p>
				<p>
					+32 478 26 01 57
				</p>
				<p>
					<a target="_blank" href="https://www.google.be/maps/dir/?api=1&destination=Rue%20de%20la%20Chapelle+47+1430+Rebecq+Belgique">Itinéraire</a>
				</p>
			</Addr>
		</Coord>
	);
}

