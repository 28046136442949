/*
 *
 */
import * as React from "react";
import styled from 'styled-components';
import { Link } from 'gatsby';

/*
 *
 */
const H2 = styled.h2`
	margin:0 auto 4rem;

	text-align:center;
	font-weight:400;
	font-size:1.8rem;
	line-height:1.3888;
	letter-spacing:.035em;
	text-decoration:none;
`;

/*
 *
 */
export default ({ children }) => {
	return (
		<H2 as="h2">
			{children}
		</H2>
	);
}
