/*
 *
 */
import * as React from "react";
import styled from 'styled-components';

/*
 *
 */
const Hours = styled.div`
	margin:0 auto;
	width:100%;
	max-width:320px;

	@media (min-width:1024px) {
		max-width:920px;
		display:flex;
		justify-content:flex-start;
		align-items:flex-start;

		border-left:1px solid rgba(151,151,151,.5);
	}
`;

const Day = styled.div`
	padding:1.6rem 0;
	border-bottom:1px solid rgba(151,151,151,.5);

	font-size:1.4rem;
	font-weight:500;
	letter-spacing:0.025em;
	display:flex;
	justify-content:space-between;
	box-sizing:border-box;

	&:first-child {
		padding-top:0;
	}

	@media (min-width:1024px) {
		display:block;
		height:100%;
		width:14.2857142857%;
		min-width:1px;
		border-bottom:0;
		border-right:1px solid rgba(151,151,151,.5);

		&,
		&:first-child {
			padding:.3rem 1.2rem;
		}
	}
`;

const DayName = styled.div`
	@media (min-width:1024px) {
		margin:0 0 1.25rem;
		font-size:1.6rem;
	}
`;

const Schedule = styled.div`
`;

/*
 *
 */
export default ({ mon='', tue='', wed='', thu='', fri='', sat='', sun='' }) => {
	return (
		<Hours>
			{[['Lundi', mon], ['Mardi', tue], ['Mercredi', wed], ['Jeudi', thu], ['Vendredi', fri], ['Samedi', sat], ['Dimanche', sun]].map(([name, schedule]) => (
				<Day key={name}>
					<DayName>{name}</DayName>
					<Schedule>{schedule}</Schedule>
				</Day>
			))}
		</Hours>
	);
}
